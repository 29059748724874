<template>
  <div class="oilsCardList">
    <divider>会员卡</divider>
    <div class="cardCont" @click="$router.push({path:'money',query:{id:my_cardInfor.id}})" v-if="my_cardInfor">
      <span>{{my_cardInfor.name}}</span>
      <span>此卡为通用会员卡</span>
      <div class="balance">
        <span>{{user_info.wallet.balance}}</span>
        <span>元</span>
      </div>
    </div>
    
    <template v-if="cardList.length>0">
      <divider>加油卡</divider>
      <div v-for="(item, index) in cardList" :key="index" class="cardCont" @click="toDetail(item,0)">
        <span>{{item.name}}</span>
        <span>加油卡(此卡为油站赠送)</span>
        <div class="balance">
          <span>{{item.balance}}</span>
          <span>元</span>
        </div>
      </div>
    </template>
    
    <template v-if="myFamilyCards.length>0">
      <divider>亲属卡</divider>
      <div v-for="(item, index) in myFamilyCards" :key="index + 'qsk'" class="cardCont">
        <span>亲属卡({{item.account_name}})</span>
        <span>此卡为{{item.real_name}}赠送</span>
        <div class="balance">
          <span>{{item.quota}}</span>
          <span>元额度</span>
        </div>
      </div>
    </template>
    
    <template v-if="teamCardList.length>0">
      <divider>车队卡</divider>
      <div v-for="(item, index) in teamCardList" :key="index + 'id'" class="cardCont" @click="item.parent?toDetail(item,1):$router.push('/carteamCard')">
        <span>{{item.card.name}}</span>
        <span v-if="item.parent">此卡为{{item.team.name}}车队分配</span>
        <span v-else>此卡为{{item.team.name}}车队主卡</span>
        <div class="balance">
          <span>{{item.balance}}</span>
          <span>元额度</span>
        </div>
      </div>
    </template>
    <!-- <div class="noCard" v-else>
      <img src="@static/vip/noCard.png" alt="">
      <p>暂无油卡</p>
    </div> -->
    <!-- <div class="addBtn">新增加油卡</div> -->
  </div>
</template>

<script>
import creditshop from "@/api/creditshop"
import UserInfo from "@/api/user";
import { Divider } from 'vant';
export default {
  components: {
    Divider
  },
  data() {
    return {
      cardList: [],
      user_info: {
        wallet: {
          balance: 0
        }
      },
      my_cardInfor: {
        id:"",
        name: ""
      },
      teamCardList: [],
      myFamilyCards: [],
    };
  },
  filters: {
    setColor: function(val) {
      switch (val) {
        case "0":
          return "linear-gradient(45deg,#34B050,#85CF2D)";
        case "1":
          return "linear-gradient(45deg,#5961F7,#654EA3)";
        case "2":
          return "linear-gradient(45deg,#FF9645,#FFC517)";
        case "3":
          return "";
        default:
          break;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getUserBalance();
      this.MygetVipList();
      this.getTeamList();
      this.myFamilyCard();
    });
  },
  methods: {
     Color(){
      let r = Math.floor(Math.random()*255);
      let g = Math.floor(Math.random()*255);
      let b = Math.floor(Math.random()*255);
      let color = 'rgba('+ r +','+ g +','+ b +',0.8)';
      console.log(color)
      return color;
    },
    async getUserBalance() {
      try {
        const res = await UserInfo.getUserMember();
        if (res.code === 200) {
          this.user_info = res.data;
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 获取我的会员卡信息
    async MygetVipList() {
      try {
        const res = await UserInfo.my_card();
        if (res.code == 200) {
          this.my_cardInfor = res.data.card;
          this.cardList = res.data.card_info;
        } else {
          throw res.msg;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 获取车队卡
    async getTeamList() {
      try {
        const res = await creditshop.myTeamCards();
        if (res.code == 200) {
          this.teamCardList = res.result;
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 获取亲属卡
    async myFamilyCard() {
      try {
        const res = await creditshop.myFamilyCard();
        if (res.code == 200) {
          this.myFamilyCards = res.data;
        }
      } catch (error) {
        console.log(error)
      }
    },
    toDetail(item,idx) {
      if (idx == 0) {
        this.$router.push("/oilCardDetail?id=" + item.id);
      } else {
        this.$router.push({
          path: "/oilCardDetail",
          query: {
            teamcard_id: item.id
          }
        });
      }
      
    }
  }
};
</script>


<style lang="scss" scoped>
.oilsCardList {
  height: 100vh;
  background: #fff;
  padding: 15px 10px 0;
  position: relative;
  overflow: auto;

  .cardCont:nth-child(2n + 1) {
    background: linear-gradient(45deg, #ff9645, #ffc517);
  }

  .noCard {
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      color: #999;
      font-size: 16px;
    }
  }

  .cardCont {
    width: 100%;
    height: 110px;
    border-radius: 10px;
    padding: 30px 24px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
    position: relative;
    background: linear-gradient(45deg, #34b050, #85cf2d);

    span:nth-child(1) {
      font-size: 20px;
    }

    .balance {
      position: absolute;
      right: 24px;
      bottom: 24px;

      span:nth-child(1) {
        font-size: 24px;
        margin-right: 4px;
      }
    }
  }

  .addBtn {
    width: 300px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    position: absolute;
    bottom: 10px;
    left: 50%;
    margin-left: -150px;
    background: linear-gradient(90deg, #85cf2d, #34b050);
    border-radius: 5px;
  }
}
</style>